import {Button, Grid, GridColumn, Segment} from "semantic-ui-react";
import {Item} from "./client";
import {ComponentIcon} from "./ComponentIcon";
import {useState} from "react";

export const ComponentItem = ({item} : { item : Item}) => {
    const [state, setState] : [{valid: boolean}, any] = useState({valid: true});
    function deleteItem() {
        fetch(`https://api.qs.cx/item/delete/${item.id}`, {method: "DELETE"})
            .then(res => res.json())
            .then(
                (result) => {
                        setState({valid: false})
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }

    return (
        <Segment className="item">
            {state.valid ?
            <div>
                {item.id}
            <Grid>
                <GridColumn className="one wide column">
                    <ComponentIcon item={item}></ComponentIcon>
                </GridColumn>
                <GridColumn className="middle aligned fourteen wide column">
                    {item.meta.name}
                </GridColumn>
            </Grid>
            <Segment>
                {item.meta.text}
            </Segment>
            <Segment>
                {item.meta.source_title}
            </Segment>
            <Button onClick={deleteItem}>
                Delete
            </Button>
                </div>
            : "DELETED" }
        </Segment>
    );
};