import React, {useEffect} from "react";
import {useState} from 'react';
import {Item} from "./client";
import {ComponentItem} from "./ComponentItem";




export function ComponentPerspective() {
    const [state, setState] : [{items: Item[]}, any] = useState({
        items: []
    });
    function componentDidMount() {
        fetch("https://api.qs.cx/retrieve")
            .then(res => res.json())
            .then(
                (result) => {
                    setState({
                        items: result
                    });
                    console.log(result)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setState({
                        items: []
                    });
                }
            )
    }

    return (
        <div>
        <button onClick={() => componentDidMount() }>LOAD</button>
        <ul>
            {state.items ?
                state.items.map(item => (
                /*                    <ComponentItem></ComponentItem>*/
                <ComponentItem item={item}></ComponentItem>
                ))
                 : ""
            }
        </ul>
        </div>
    );
}
